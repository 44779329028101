<template>
  <layout-vertical>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <template #breadcrumb>
      <breadcrumb />
    </template>

    <template #footer>
      <custom-footer v-show="showFooter" />
    </template>

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import Breadcrumb from '@/@custom/layout/components/CsBreadcrumb.vue'
import CustomFooter from '@/@custom/layout/components/CsFooter.vue'
import Navbar from './navbar/Navbar.vue'

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
    Navbar,
    Breadcrumb,
    CustomFooter,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
      showFooter: $themeConfig.layout.footer.visible,
    }
  },
}
</script>
